import { gql } from '@apollo/client';

// USER
export const CREATE_USER = gql`
  mutation createUser(
    $name: String
    $email: String
    $phone: String
    $password: String
  ) {
    createUser(name: $name, email: $email, phone: $phone, password: $password) {
      userId
      imageUrl
      name
      role
      accessToken
      refreshToken
    }
  }
`;

export const LOGIN = gql`
  mutation login($email: String, $password: String) {
    login(email: $email, password: $password) {
      userId
      imageUrl
      name
      role
      accessToken
      refreshToken
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation resetPassword($oldPassword: String, $newPassword: String) {
    resetPassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $name: String
    $email: String
    $imageUrl: String
    $phone: String
    $country: String
    $city: String
    $address: String
  ) {
    updateUser(
      id: $id
      name: $name
      email: $email
      imageUrl: $imageUrl
      phone: $phone
      country: $country
      city: $city
      address: $address
    ) {
      id
    }
  }
`;

export const GET_ALL_USERS = gql`
  query {
    users {
      imageUrl
      name
      phone
      role
      email
      active
      lastLogin
    }
  }
`;

export const GET_USER = gql`
  query {
    user {
      imageUrl
      name
      phone
      email
      country
      city
      address
    }
  }
`;

export const GET_ME_USER = gql`
  query {
    meUser {
      imageUrl
      name
      phone
      email
      country
      city
      address
    }
  }
`;

export const SEND_RESET_LINK = gql`
  mutation sendResetLink($email: String!) {
    sendResetLink(email: $email)
  }
`;

export const PASSWORD_REST = gql`
  mutation newPassword($userId: ID!, $token: String!, $password: String) {
    newPassword(userId: $userId, token: $token, password: $password) {
      id
      name
      email
    }
  }
`;

export const GET_ME_INFO = gql`
  query {
    meInfo {
      name
      phone
      email
      country
      city
      address
    }
  }
`;

export const CREATE_ASSISTANCE = gql`
  mutation createAssistance(
    $name: String
    $email: String
    $phone: Int
    $description: String
  ) {
    createAssistance(
      name: $name
      email: $email
      phone: $phone
      description: $description
    )
  }
`;
// PRODUCT
export const GET_PRODUCTS = gql`
  query {
    products {
      id
      taxable
      vatRate
      productCode
      imageUrl
      name
      category
      description
      qty
      priceInBIF
      priceInUSD
      priceInEUR
      discount
      # currency//
      options {
        title
        variations {
          name
          qty
          priceInBIF
          priceInUSD
          priceInEUR
        }
      }
      createdBy {
        id
        name
      }
      updatedBy {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const GET_PRODUCT = gql`
  query product($id: String!) {
    product(id: $id) {
      id
      taxable
      vatRate
      productCode
      imageUrl
      name
      category
      description
      qty
      priceInBIF
      priceInUSD
      priceInEUR
      discount
      # currency
      options {
        title
        variations {
          name
          qty
          priceInBIF
          priceInUSD
          priceInEUR
        }
      }
      createdBy {
        id
        name
      }
      updatedBy {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

// ORDER
export const CREATE_ORDER = gql`
  mutation Mutation(
    $items: [ItemInput]
    $name: String
    $email: String
    $phone: String
    $country: String
    $city: String
    $address: String
  ) {
    createOrder(
      items: $items
      name: $name
      email: $email
      phone: $phone
      country: $country
      city: $city
      address: $address
    ) {
      id
      orderId
    }
  }
`;

export const GET_ORDERS = gql`
  query {
    orders {
      id
      status
      currency
      orderId
      total
      createdBy {
        id
        name
      }
      updatedBy {
        id
        name
      }
      createdAt
    }
  }
`;

export const GET_ORDER = gql`
  query order($id: String!) {
    order(id: $id) {
      id
      status
      orderId
      currency
      paymentMethod

      items {
        item_code
        name
        option {
          title
          name
        }
        qty
        price
        amount
        vat
        total
      }
      amount
      vat
      total
      createdBy {
        id
        name
        email
        phone
        country
        city
        address
      }
      createdAt
    }
  }
`;

export const DELETE_ORDER = gql`
  mutation deleteOrder($id: String!) {
    deleteOrder(id: $id) {
      id
    }
  }
`;
