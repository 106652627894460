import { Center, Spinner, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

export default function Loading() {
  return (
    <Center minH={200}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color={useColorModeValue('brand.primary', 'brand.primaryLight')}
        size="xl"
      />
    </Center>
  );
}
