import React from 'react';
import Moment from 'react-moment';

export const findMin = (data, currency) => {
  if (currency === 'BIF') {
    let result = data[0].priceInBIF;
    data.map(val => {
      if (val.priceInBIF < result) {
        result = val.priceInBIF;
      }
    });
    return result;
  } else if (currency === 'USD') {
    let result = data[0].priceInUSD;
    data.map(val => {
      if (val.priceInUSD < result) {
        result = val.priceInUSD;
      }
    });
    return result;
  } else {
    let result = data[0].priceInEUR;
    data.map(val => {
      if (val.priceInEUR < result) {
        result = val.priceInEUR;
      }
    });
    return result;
  }
};

export const findMax = (data, currency) => {
  if (currency === 'BIF') {
    let result = data[0].priceInBIF;
    data.map(val => {
      if (val.priceInBIF > result) {
        result = val.priceInBIF;
      }
    });
    return result;
  } else if (currency === 'USD') {
    let result = data[0].priceInUSD;
    data.map(val => {
      if (val.priceInUSD > result) {
        result = val.priceInUSD;
      }
    });
    return result;
  } else {
    let result = data[0].priceInEUR;
    data.map(val => {
      if (val.priceInEUR > result) {
        result = val.priceInEUR;
      }
    });
    return result;
  }
};

export const trimTitle = word => {
  const maxLength = 20;

  if (word.length <= maxLength) {
    return word;
  } else {
    return word.slice(0, maxLength) + '...';
  }
};

export function Timer(date) {
  var myDate = date / 1000;
  var unixTimestamp = myDate;
  return (
    <>
      <Moment format="DD/MM/YYYY" unix>
        {unixTimestamp}
      </Moment>
    </>
  );
}
