import React from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Button,
} from '@chakra-ui/react';
import AssistanceForm from '../components/AssistanceForm';

function AssistancePopover() {
  const [isOpen, setIsOpen] = React.useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  return (
    <Popover
      isOpen={isOpen}
      onOpen={open}
      onClose={close}
      closeOnBlur={false}
      placement={'top'}
    >
      <PopoverTrigger>
        <Button
          position="fixed"
          bottom="5%"
          left="20px"
          zIndex="100"
          bg={'brand.primary'}
          color={'white'}
          size="md"
          rounded="full"
          boxShadow="lg"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
            bg: 'brand.hoverPrimary',
          }}
        >
          help?
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        {/* <PopoverHeader>Support</PopoverHeader> */}
        <PopoverBody>
          <AssistanceForm close={close} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default AssistancePopover;
