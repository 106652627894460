import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CHANGE_PASSWORD } from '../config/graphql';
import Error from '../components/Error';

export default function PasswordChange() {
  const [password, setPassword] = useState({});
  const [resetPassword, { error, loading }] = useMutation(CHANGE_PASSWORD);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const handleOnChange = e => {
    setPassword({ ...password, [e.target.name]: e.target.value });
  };
  const navigate = useNavigate();

  const handleSubmit = e => {
    resetPassword({
      variables: { ...password },
    })
      .then(data => {
        alert('Password updated successfully!');
        navigate('/');
      })
      .catch(error => console.log(error));
  };

  return (
    <Flex
      //   minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack
        spacing={4}
        w={'full'}
        maxW={'md'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}
      >
        <Heading lineHeight={1.1} fontSize={{ base: 'xl', md: '2xl' }}>
          Change Password
        </Heading>
        <FormControl id="password-old" isRequired>
          <FormLabel>Current Password</FormLabel>
          <Input
            _placeholder={{ color: 'gray.500' }}
            type="password"
            name="oldPassword"
            onChange={handleOnChange}
          />
        </FormControl>
        <FormControl id="password" isRequired>
          <FormLabel>New Password</FormLabel>
          <Input type="password" name="newPassword" onChange={handleOnChange} />
        </FormControl>
        {error ? <Error error={error} /> : <></>}
        <Stack spacing={6}>
          <Button
            onClick={() => {
              handleSubmit();
            }}
            bg={'brand.primary'}
            color={'white'}
            _hover={{
              bg: 'brand.hoverPrimary',
            }}
            isLoading={loading}
            loadingText="Loading"
            spinnerPlacement="end"
          >
            Update Password
          </Button>
        </Stack>
      </Stack>
    </Flex>
  );
}
