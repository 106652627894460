import React, { useEffect } from 'react';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Heading,
  Text,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

const Terms = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <Box style={{ minHeight: '80vh' }}>
      <Container maxW={'container.xl'}>
        <Breadcrumb
          py={5}
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink onClick={() => navigate('/')}>Home</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Terms and Conditions</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Heading mb={2}>Terms and Conditions</Heading>

        <Text fontSize="lg" mb={4}>
          Welcome to Future City Technology! These terms and conditions, herein
          referred to as "Agreement," govern your use of our e-commerce platform
          available at fctech.bi. By accessing or using our services, you agree
          to be bound by this Agreement. If you do not agree with any part of
          these terms, you may not access the service.
        </Text>

        <Text fontSize="lg" mb={4}>
          1. <strong>General Provisions</strong>
          <br />
          1.1. Future City Technology reserves the right to modify, suspend, or
          terminate the service at any time without prior notice.
          <br />
          1.2. Users are responsible for ensuring the accuracy of the
          information provided during registration and updating their details
          promptly.
        </Text>

        <Text fontSize="lg" mb={4}>
          2. <strong>Intellectual Property</strong>
          <br />
          2.1. All content, trademarks, logos, and intellectual property
          displayed on Future City Technology are the property of Future City
          Technology or its licensors.
          <br />
          2.2. Users may not reproduce, distribute, or modify any content from
          the platform without prior written consent.
        </Text>

        <Text fontSize="lg" mb={4}>
          3. <strong>Privacy and Security</strong>
          <br />
          3.1. Future City Technology collects and processes personal data in
          accordance with its Privacy Policy, which users are encouraged to
          review.
          <br />
          3.2. Users are responsible for maintaining the confidentiality of
          their account credentials and should notify Future City Technology
          immediately of any unauthorized access.
        </Text>

        {/* ... Additional sections ... */}

        <Text fontSize="lg" mb={4}>
          10. <strong>Termination</strong>
          <br />
          10.1. Future City Technology reserves the right to terminate user
          accounts for violations of these terms and conditions or any unlawful
          activities.
          <br />
          10.2. Users may terminate their accounts by contacting
          support@fctech.bi.
        </Text>

        <Text fontSize="lg" mb={4}>
          This Agreement constitutes the entire understanding between the user
          and Future City Technology. Any failure to enforce any part of this
          Agreement shall not constitute a waiver of such provision.
        </Text>

        <Text fontSize="lg" mb={4}>
          If you have any questions or concerns regarding these terms and
          conditions, please contact us at{' '}
          <a href="mailto:support@fctech.bi">support@fctech.bi</a>.
        </Text>
      </Container>
    </Box>
  );
};

export default Terms;
