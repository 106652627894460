import { useLocation } from 'react-router';
import { Navigate, Outlet } from 'react-router-dom';

const useAuth = () => {
  let user = JSON.parse(localStorage.getItem('user'));
  return user;
};

const ProtectedRoutes = () => {
  const location = useLocation();
  const isAuth = useAuth();
  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" replace state={{ from: location }} />
  );
};

const ProtectedRouteLogin = ({ children }) => {
  let user = JSON.parse(localStorage.getItem('user'));
  if (user) {
    return <Navigate to="/" />;
  } else {
    return children;
  }
};

export { ProtectedRouteLogin };

export default ProtectedRoutes;
