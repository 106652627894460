import { Box, Image, Text, Wrap, WrapItem } from '@chakra-ui/react';
import React from 'react';
import slider1 from '../assets/slider2.svg';

export default function SlidingHeader() {
  return (
    <Wrap align="center" justify="center" spacing={6}>
      <WrapItem>
        <Image width={{ base: '100%', md: '500px' }} src={slider1} />
      </WrapItem>
      <WrapItem maxW={500}>
        <Box
          fontWeight={700}
          fontSize={30}
          display={{ base: 'none', md: 'none', lg: 'block' }}
        >
          <Text>Discover Tomorrow's Tech Today.</Text>{' '}
          <Text color={'brand.primary'}>Future City Technology, </Text>
          <Text>Where Innovation Lives.</Text>
        </Box>
      </WrapItem>
    </Wrap>
  );
}
