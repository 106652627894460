// Cart.js
import React, { useEffect } from 'react';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  CardBody,
  Container,
  Divider,
  HStack,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
} from '@chakra-ui/react';
import CartItem from '../components/Product/CartItem';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

const Cart = ({ cartItems, updateCartItem, removeItem }) => {
  const handleUpdate = (item, qty) => {
    updateCartItem(item, qty);
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => total + item.price * item.qty, 0);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const navigate = useNavigate();

  return (
    <Container maxW={'container.xl'}>
      <Container py={5} maxWidth={'container.xl'}>
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink onClick={() => navigate('/')}>Home</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Shopping Cart</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Container>
      <Wrap justify={'space-between'}>
        <WrapItem w={{ base: 'full', md: '65%' }}>
          <Card bg={'gray.50'} w={'full'}>
            <CardBody>
              <Text fontWeight={600} fontSize={'xl'}>
                Cart Items
              </Text>
              <Divider />
              <br />
              {cartItems?.map((val, index) => (
                <CartItem
                  key={index}
                  removeItem={removeItem}
                  handleUpdate={handleUpdate}
                  product={val}
                />
              ))}
            </CardBody>
          </Card>
        </WrapItem>

        <WrapItem w={{ base: 'full', md: '30%' }}>
          <Box w={'full'}>
            <Card bg={'gray.50'} w={'full'}>
              <CardBody>
                <Text fontWeight={600} fontSize={'xl'}>
                  Cart Total
                </Text>
                <Divider />
                <br />
                <HStack justify={'space-between'}>
                  <Text>Subtotal</Text>
                  <Text fontWeight={500} fontSize={'lg'}>
                    {calculateTotal()?.toLocaleString('en-FR', {})}{' '}
                    {cartItems[0]?.currency}
                  </Text>
                </HStack>
                <HStack justify={'space-between'}>
                  <Text>Tax</Text>
                  <Text fontWeight={500} fontSize={'md'}>
                    {Math.round(calculateTotal() * 0.18)?.toLocaleString(
                      'en-FR',
                      {}
                    )}{' '}
                    {cartItems[0]?.currency}
                  </Text>
                </HStack>
                <Divider />
                <br />
                <HStack justify={'space-between'}>
                  <Text>Total</Text>
                  <Text fontWeight={500} fontSize={'xl'}>
                    {Math.round(calculateTotal() * 1.18)?.toLocaleString(
                      'en-FR',
                      {}
                    )}{' '}
                    {cartItems[0]?.currency}
                  </Text>
                </HStack>
              </CardBody>
            </Card>
            <br />
            <Button
              w={'full'}
              bg={'brand.primary'}
              onClick={() => navigate('/checkout')}
              color={useColorModeValue('white', 'gray.900')}
              textTransform={'uppercase'}
              _hover={{
                transform: 'translateY(2px)',
                boxShadow: 'lg',
              }}
            >
              Proceed to Checkout
            </Button>
          </Box>
        </WrapItem>
      </Wrap>
      <br />
      <br />
    </Container>
  );
};

export default Cart;
