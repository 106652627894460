import {
  Flex,
  Circle,
  Box,
  Image,
  Badge,
  useColorModeValue,
  Button,
  Text,
  Stack,
} from '@chakra-ui/react';
// import { BsStar, BsStarFill, BsStarHalf } from 'react-icons/bs'
import { findMax, findMin } from '../../config/functions';
import { useNavigate } from 'react-router-dom';

import defaultImage from '../../assets/default.png';

function ProductCard({ data, addToCart, currency }) {
  const navigate = useNavigate();
  const imageUrl =
    process.env.REACT_APP_IMAGE_URL || 'http://192.168.0.23:2222';

  return (
    <Flex p={4} w="full" alignItems="center" justifyContent="center">
      <Box
        bg={useColorModeValue('white', 'gray.800')}
        w={{ base: '100%', md: '300px' }}
        borderWidth="1px"
        rounded="lg"
        shadow="lg"
        position="relative"
      >
        {data.discount > 0 && (
          <Circle
            as={'span'}
            // size="10px"
            w={10}
            h={10}
            fontWeight={600}
            // color={'white'}
            position="absolute"
            top={2}
            right={2}
            bg="red.200"
          >
            -{data.discount * 100}%
          </Circle>
        )}

        <Box
          w={'100%'}
          h={'200px'}
          overflow="hidden"
          onClick={() => navigate(`/products/${data.id}`)}
        >
          <Image
            transition="0.3s ease-in-out"
            _hover={{
              transform: 'scale(1.05)',
            }}
            objectFit="contain"
            cursor={'pointer'}
            w="100%"
            h="100%"
            src={
              data.imageUrl.length >= 1
                ? imageUrl + data.imageUrl[0]
                : defaultImage
            }
            alt={`Picture of ${data.name}`}
            roundedTop="lg"
          />
        </Box>

        <Box p="6">
          <Box display="flex" alignItems="baseline">
            <Badge rounded="full" px="2" fontSize="0.7em" colorScheme="red">
              {data.category}
            </Badge>
          </Box>
          <Flex mt="1" justifyContent="space-between" alignContent="center">
            <Box
              fontSize="1em"
              fontWeight="semibold"
              as="h4"
              lineHeight="tight"
              isTruncated
              cursor={'pointer'}
              onClick={() => navigate(`/products/${data.id}`)}
            >
              {data.name}
            </Box>
          </Flex>

          <Flex justifyContent="space-between" alignContent="center">
            {/* <Rating rating={data.rating} numReviews={data.numReviews} /> */}
            {data?.options?.variations?.length > 0 ? (
              <Box fontSize="md">
                {findMin(data?.options?.variations, currency)?.toLocaleString(
                  'en-FR',
                  {}
                )}{' '}
                <Box as="span" color={'gray.600'} fontSize="md">
                  {data.currency}
                </Box>{' '}
                -{' '}
                {findMax(data?.options?.variations, currency)?.toLocaleString(
                  'en-FR',
                  {}
                )}{' '}
                <Box as="span" color={'gray.600'} fontSize="md">
                  {currency}
                </Box>
              </Box>
            ) : (
              <Box fontSize="md">
                {data.discount ? (
                  <Stack direction={['column', 'row']}>
                    <Text textDecoration={'line-through'} color={'gray'}>
                      {currency === 'BIF' ? (
                        data.priceInBIF?.toLocaleString('en-FR', {})
                      ) : (
                        <></>
                      )}
                      {currency === 'USD' ? (
                        data.priceInUSD?.toLocaleString('en-FR', {})
                      ) : (
                        <></>
                      )}
                      {currency === 'EUR' ? (
                        data.priceInEUR?.toLocaleString('en-FR', {})
                      ) : (
                        <></>
                      )}
                    </Text>
                    <Text>
                      {currency === 'BIF' ? (
                        (data.priceInBIF * (1 - data.discount))?.toLocaleString(
                          'en-FR',
                          {}
                        )
                      ) : (
                        <></>
                      )}
                      {currency === 'USD' ? (
                        (data.priceInUSD * (1 - data.discount))?.toLocaleString(
                          'en-FR',
                          {}
                        )
                      ) : (
                        <></>
                      )}
                      {currency === 'EUR' ? (
                        (data.priceInEUR * (1 - data.discount))?.toLocaleString(
                          'en-FR',
                          {}
                        )
                      ) : (
                        <></>
                      )}
                    </Text>
                    <Box as="span" color={'gray.600'} fontSize="md">
                      {currency}
                    </Box>
                  </Stack>
                ) : (
                  <>
                    {currency === 'BIF' ? (
                      (data.priceInBIF * (1 - data.discount))?.toLocaleString(
                        'en-FR',
                        {}
                      )
                    ) : (
                      <></>
                    )}
                    {currency === 'USD' ? (
                      (data.priceInUSD * (1 - data.discount))?.toLocaleString(
                        'en-FR',
                        {}
                      )
                    ) : (
                      <></>
                    )}
                    {currency === 'EUR' ? (
                      (data.priceInEUR * (1 - data.discount))?.toLocaleString(
                        'en-FR',
                        {}
                      )
                    ) : (
                      <></>
                    )}{' '}
                    <Box as="span" color={'gray.600'} fontSize="md">
                      {currency}
                    </Box>
                  </>
                )}
              </Box>
            )}
          </Flex>
          <Box
            mt={3}
            display={{
              base: 'none',
              md: 'none',
              lg: 'none',
              sm: 'block',
              sx: 'block',
            }}
          >
            {data?.options?.variations?.length > 0 ? (
              <Button
                bg={'brand.primary'}
                color={'white'}
                w={'full'}
                _hover={{ bg: 'brand.hoverPrimary' }}
                onClick={() => navigate(`/products/${data.id}`)}
              >
                Options
              </Button>
            ) : (
              <Button
                onClick={() => {
                  addToCart({
                    id: data.id,
                    name: data.name,
                    item_code: data.productCode,
                    imageUrl: data.imageUrl[0],
                    qty: data.qty,
                    currency: currency,
                    price:
                      currency === 'BIF'
                        ? data.discount
                          ? data.priceInBIF * (1 - data.discount)
                          : data.priceInBIF
                        : currency === 'USD'
                        ? data.discount
                          ? data.priceInUSD * (1 - data.discount)
                          : data.priceInUSD
                        : data.discount
                        ? data.priceInEUR * (1 - data.discount)
                        : data.priceInEUR,
                  });
                }}
                bg={'brand.primary'}
                color={'white'}
                w={'full'}
                _hover={{ bg: 'brand.hoverPrimary' }}
              >
                Add to Cart
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Flex>
  );
}

export default ProductCard;
