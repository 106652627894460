import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Divider,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLocationDot,
  faMailBulk,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { faSquareWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FloatingWhatsApp } from 'react-floating-whatsapp2';
import logo from '../assets/logo.png';
const phone = process.env.PHONE_NUMBER || '25766536050';

export default function Contact() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <Box style={{ minHeight: '80vh' }}>
      <FloatingWhatsApp
        statusMessage={'online'}
        phoneNumber={phone}
        accountName="Future City Technology"
        avatar={logo}
      />
      <Container py={5} maxWidth={'container.xl'}>
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink onClick={() => navigate('/')}>Home</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Contact us</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Container>
      <Box bg={'gray.100'}>
        <Container maxW={'container.xl'}>
          <Text pt={5} textAlign={'center'} fontSize={'4xl'} fontWeight={700}>
            We are located in Bujumbura, Burundi
          </Text>
          <br />
          <Box>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3349.1657499623625!2d29.361547285425317!3d-3.3863678639859023!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19c18318dceb9c75%3A0xcb46479cd70d6650!2sN.J%20Computer%20and%20Networks!5e0!3m2!1sfr!2sbi!4v1708165224750!5m2!1sfr!2sbi"
              width="100%"
              height="600"
              title="google-maps"
              style={{ border: 0, borderRadius: '10px' }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Box>
          <br />
          <Box>
            <HStack
              cursor={'pointer'}
              onClick={() =>
                (window.location.href =
                  'https://maps.app.goo.gl/HQJ7oVxvmbsQGQo48')
              }
              align={'center'}
            >
              <Text w={50} fontSize={'4xl'}>
                <FontAwesomeIcon icon={faLocationDot} />
              </Text>
              <VStack>
                <Box>
                  <Text fontSize={'2xl'}>Address</Text>
                  <Text color={'gray.500'}>Bujumbura Burundi</Text>
                </Box>
              </VStack>
            </HStack>
            <br />
            <Divider />
            <br />
            <HStack
              cursor={'pointer'}
              onClick={() => (window.location.href = 'tel:+25766536050')}
              align={'center'}
            >
              <Text w={50} fontSize={'4xl'}>
                <FontAwesomeIcon icon={faPhone} />
              </Text>
              <VStack>
                <Box>
                  <Text fontSize={'2xl'}>Call us on</Text>
                  <Text color={'gray.500'}> +257 66 53 60 50</Text>
                </Box>
              </VStack>
            </HStack>
            <br />
            <Divider />
            <br />
            <HStack
              cursor={'pointer'}
              onClick={() => (window.location.href = 'tel:+25766536050')}
              align={'center'}
            >
              <Text w={50} fontSize={'4xl'}>
                <FontAwesomeIcon icon={faSquareWhatsapp} size={'lg'} />
              </Text>
              <VStack>
                <Box>
                  <Text fontSize={'2xl'}>whatsapp on</Text>
                  <Text color={'gray.500'}> +257 66 53 60 50</Text>
                </Box>
              </VStack>
            </HStack>
            <br />
            <Divider />
            <br />
            <HStack
              cursor={'pointer'}
              onClick={() => (window.location.href = 'mailto:info@fctech.bi')}
              align={'center'}
            >
              <Text w={50} fontSize={'4xl'}>
                <FontAwesomeIcon icon={faMailBulk} />
              </Text>
              <VStack>
                <Box>
                  <Text fontSize={'2xl'}>Email</Text>
                  <Text color={'gray.500'}>info@fctech.bi</Text>
                </Box>
              </VStack>
            </HStack>
            <br />
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
