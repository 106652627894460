import React from 'react';
import {
  Card,
  CardBody,
  Button,
  Flex,
  HStack,
  Image,
  Text,
} from '@chakra-ui/react';

export default function CartItem({ product, handleUpdate, removeItem }) {
  const imageUrl =
    process.env.REACT_APP_IMAGE_URL || 'http://192.168.0.23:2222';
  return (
    <Card mt={2}>
      <CardBody>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <Image
              src={imageUrl + product.imageUrl}
              alt={product.name}
              boxSize="50px"
              objectFit="contain"
              mr={4}
            />
          </div>

          <div style={{ width: '90%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Text>{product.name}</Text>

                {product.option ? (
                  <Text fontStyle={'italic'}>
                    ({product.option.title}: {product.option.name})
                  </Text>
                ) : (
                  <></>
                )}
              </div>
              <Text fontWeight={500}>
                {(product.price * product.qty)?.toLocaleString('en-FR', {})}{' '}
                {product.currency}
              </Text>
            </div>
            <br />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <HStack maxW="180px">
                <Button
                  onClick={() => handleUpdate(product, product.qty + 1)}
                  ml={2}
                  size={'sm'}
                >
                  +
                </Button>
                <Text>{product.qty}</Text>
                <Button
                  onClick={() => handleUpdate(product, product.qty - 1)}
                  ml={2}
                  size={'sm'}
                  isDisabled={product.qty <= 1}
                >
                  -
                </Button>
              </HStack>

              <Flex ml="auto" paddingInline={'1'}>
                <Button
                  onClick={() => removeItem(product)}
                  size={'sm'}
                  textColor="white"
                  bg="brand.primary"
                  _hover={{
                    bg: 'brand.hoverPrimary',
                  }}
                >
                  remove
                </Button>
              </Flex>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
