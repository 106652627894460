// Checkout.js
import React, { useEffect, useState } from 'react';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  CardBody,
  Container,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useColorModeValue,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';
import Error from '../components/Error';
import { CREATE_ORDER, GET_ME_INFO } from '../config/graphql';
import { useMutation, useQuery } from '@apollo/client';

const Checkout = ({ cartItems, clearCart }) => {
  const navigate = useNavigate();

  const [order, setOrder] = useState({});
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };

  const handleClose = () => {
    setShow(!show);
    clearCart();
    navigate('/');
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const { data, error, loading } = useQuery(GET_ME_INFO);
  const [createOrder, { error: errorOrder, loading: loadingOrder }] =
    useMutation(CREATE_ORDER);

  const handleOnChange = event => {
    setOrder({ ...order, [event.target.name]: event.target.value });
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => total + item.price * item.qty, 0);
  };

  const handleOnSubmit = event => {
    order.items = cartItems;

    createOrder({
      variables: { ...order },
    })
      .then(data => {
        handleShow();
      })
      .catch(e => console.log(e));

    event.preventDefault();
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <Container maxW={'container.xl'}>
      <Container py={5} maxWidth={'container.xl'}>
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink onClick={() => navigate('/')}>Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink onClick={() => navigate('/cart')}>
              Shopping Cart
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Checkout</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Container>
      <Wrap justify={'space-between'}>
        <WrapItem w={{ base: 'full', md: '65%' }}>
          <Card bg={'gray.50'} w={'full'}>
            <CardBody>
              <Text fontWeight={600} fontSize={'xl'}>
                Billing/Shipping Information
              </Text>
              <Divider />
              <br />
              {loading ? <Loading /> : <></>}
              {error ? <Error error={error} /> : <></>}

              {data ? (
                <Stack spacing={4}>
                  <FormControl id="name">
                    <FormLabel>Full Name</FormLabel>
                    <Input
                      defaultValue={data.meInfo?.name}
                      onChange={handleOnChange}
                      name="name"
                      placeholder="ex: Arsene Nduwayo"
                      type="text"
                    />
                  </FormControl>
                  <FormControl id="phone">
                    <FormLabel>Phone number</FormLabel>
                    <Input
                      defaultValue={data.meInfo?.phone}
                      onChange={handleOnChange}
                      name="phone"
                      placeholder="ex: +25762525242"
                      type="text"
                    />
                  </FormControl>
                  <FormControl id="email">
                    <FormLabel>Email address</FormLabel>
                    <Input
                      placeholder="your-email@example.com"
                      defaultValue={data.meInfo?.email}
                      onChange={handleOnChange}
                      name="email"
                      type="email"
                    />
                  </FormControl>
                  <FormControl id="country">
                    <FormLabel>Country</FormLabel>
                    <Input
                      placeholder="Burundi"
                      defaultValue={data.meInfo?.country}
                      onChange={handleOnChange}
                      name="country"
                      type="text"
                    />
                  </FormControl>
                  <FormControl id="city">
                    <FormLabel>City/Province/State</FormLabel>
                    <Input
                      name="city"
                      placeholder="Bujumbura"
                      defaultValue={data.meInfo?.city}
                      onChange={handleOnChange}
                      type="text"
                    />
                  </FormControl>
                  <FormControl id="address">
                    <FormLabel>Address</FormLabel>
                    <Input
                      name="address"
                      placeholder="Kinanira av Itaba No 4"
                      defaultValue={data.meInfo?.address}
                      onChange={handleOnChange}
                      type="text"
                    />
                  </FormControl>
                </Stack>
              ) : (
                <></>
              )}
            </CardBody>
          </Card>
        </WrapItem>

        <WrapItem w={{ base: 'full', md: '30%' }}>
          <Box w={'full'}>
            <Card bg={'gray.50'} w={'full'}>
              <CardBody>
                <Text fontWeight={600} fontSize={'xl'}>
                  Confirm your Order
                </Text>
                <Divider />
                <br />

                <HStack justify={'space-between'}>
                  <Text fontWeight={500} fontSize={'sm'}>
                    Total({cartItems?.length})item(s):{' '}
                  </Text>
                  <Text fontWeight={500} fontSize={'md'}>
                    {Math.round(calculateTotal() * 1.18)?.toLocaleString(
                      'en-FR',
                      {}
                    )}{' '}
                    {cartItems[0]?.currency}
                  </Text>
                </HStack>
                <Divider />
                <br />
                <HStack justify={'space-between'}></HStack>
              </CardBody>
            </Card>
            <br />
            <Button
              w={'full'}
              onClick={handleOnSubmit}
              isLoading={loadingOrder}
              bg={'brand.primary'}
              color={useColorModeValue('white', 'gray.900')}
              textTransform={'uppercase'}
              _hover={{
                transform: 'translateY(2px)',
                boxShadow: 'lg',
              }}
            >
              Place Order
            </Button>
            {errorOrder ? (
              <>
                <br />
                <br />
                <Error error={errorOrder} />
              </>
            ) : (
              <></>
            )}
          </Box>
        </WrapItem>
      </Wrap>
      <br />
      <br />
      {/* MODAL TO SHOW ON SUCCESS */}
      <Modal
        size={'sm'}
        isCentered
        closeOnOverlayClick={false}
        isOpen={show}
        onClose={handleClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Order Confirmed</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text>
              Your order was successfully created. Thank you for shopping with
              us!
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              bg={'brand.primary'}
              color={'white'}
              _hover={{ bg: 'brand.hoverPrimary' }}
              onClick={handleClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default Checkout;
