import {
  Box,
  IconButton,
  Image,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';

import Slider from 'react-slick';

export default function Gallery(props) {
  const images = props.data;

  const slider = useRef(null);

  const next = () => {
    slider.current.slickNext();
  };

  const previous = () => {
    slider.current.slickPrev();
  };

  const top = useBreakpointValue({ base: '50%', md: '50%' });
  const side = useBreakpointValue({ base: '10px', md: '10px' });

  const settings = {
    dots: true,
    arrows: false,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const imageUrl =
    process.env.REACT_APP_IMAGE_URL || 'http://192.168.0.23:2222';

  return (
    <Box
      height={{ base: '300px', md: '400px' }}
      width={'full'}
      overflow={'hidden'}
    >
      <IconButton
        aria-label="left-arrow"
        bg={'brand.primary'}
        _hover={{ bg: 'brand.hoverPrimary' }}
        color={'white'}
        borderRadius="full"
        position="absolute"
        left={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => previous()}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </IconButton>

      {/* Right Icon */}
      <IconButton
        aria-label="right-arrow"
        // bg={useColorModeValue('red.700', 'red.300')}
        bg={'brand.primary'}
        _hover={{ bg: 'brand.hoverPrimary' }}
        color={'white'}
        borderRadius="full"
        position="absolute"
        right={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => next()}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </IconButton>

      <Slider {...settings} ref={slider}>
        {images.map((image, index) => (
          <Box key={index}>
            <Image
              rounded={'md'}
              px={10}
              fit={'contain'}
              align={'center'}
              w={'100%'}
              h={{ base: '100%', sm: '400px', lg: '500px' }}
              src={imageUrl + image}
              alt={`Image ${index + 1}`}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
