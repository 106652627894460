import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { isValidEmail } from '../config/validations';
import { useMutation } from '@apollo/client';
import { CREATE_USER } from '../config/graphql';
import Error from '../components/Error';
import Success from '../components/Success';

export default function Signup() {
  const [showPassword, setShowPassword] = useState(false);

  const [user, setUser] = useState();

  const navigate = useNavigate();

  const [createUser, { error, loading }] = useMutation(CREATE_USER);

  const handleOnChange = event => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [showModal, setShowModal] = useState(false);

  const handleShow = e => {
    setShowModal(!showModal);
  };

  const handleOnSubmit = event => {
    if (!isValidEmail(user.email)) {
      alert('Invalid Email!');
    } else {
      createUser({ variables: { ...user } })
        .then(data => {
          handleShow();
          const authData = data.data.createUser;
          localStorage.setItem('user', JSON.stringify(authData));
        })
        .catch(e => console.log(e));
    }

    event.preventDefault();
  };

  return (
    <Flex
      minH={'90vh'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack maxW={'lg'} px={6} mt={5}>
        <Stack align={'center'}>
          <Heading fontSize={'2xl'} textAlign={'center'}>
            Sign up
          </Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Stack spacing={4}>
            <HStack>
              <Box>
                <FormControl id="name" isRequired>
                  <FormLabel>Name</FormLabel>
                  <Input name="name" onChange={handleOnChange} type="text" />
                </FormControl>
              </Box>
              <Box>
                <FormControl id="phone">
                  <FormLabel>Phone Number</FormLabel>
                  <Input name="phone" onChange={handleOnChange} type="text" />
                </FormControl>
              </Box>
            </HStack>
            <FormControl id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input name="email" onChange={handleOnChange} type="email" />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  name="password"
                  onChange={handleOnChange}
                  type={showPassword ? 'text' : 'password'}
                />
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() =>
                      setShowPassword(showPassword => !showPassword)
                    }
                  >
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Stack spacing={10} pt={2}>
              <Button
                isDisabled={loading}
                onClick={handleOnSubmit}
                loadingText="Submitting"
                size="lg"
                bg={'brand.primary'}
                color={'white'}
                w={'full'}
                _hover={{ bg: 'brand.hoverPrimary' }}
              >
                Sign up
              </Button>

              {error ? <Error error={error} /> : <></>}
            </Stack>
            <Stack pt={6}>
              <Text align={'center'}>
                Already a user?{' '}
                <Link
                  onClick={() => navigate('/signin')}
                  color={'brand.primary'}
                >
                  Login
                </Link>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
      {showModal ? (
        <>
          <Success
            data={{
              title: `Welcome ${user?.name}`,
              link: '/',
              description: 'Your account was created successfully!',
            }}
          />
        </>
      ) : (
        <></>
      )}
    </Flex>
  );
}
