import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Divider,
  HStack,
  Heading,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/logo.png';

import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { Timer } from '../../config/functions';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import { useQuery } from '@apollo/client';
import { GET_ORDER } from '../../config/graphql';

export default function OrderDetails() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: { id },
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <Box style={{ minHeight: '80vh' }}>
      <Container maxW={'container.xl'}>
        <Breadcrumb
          py={5}
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink onClick={() => navigate('/')}>Home</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink onClick={() => navigate('/orders')}>
              My Orders
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Order Details</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <br />
        {loading ? <Loading /> : <></>}
        {error ? <Error error={error} /> : <></>}
        {data ? (
          <Card>
            <CardBody>
              <HStack justify={'space-between'}>
                <Text fontSize={16} fontWeight={600}>
                  Order Details
                </Text>
                <Button
                  bg={'brand.primary'}
                  color={'white'}
                  _hover={{ bg: 'brand.hoverPrimary' }}
                >
                  <FontAwesomeIcon icon={faPrint} /> &nbsp; Print
                </Button>
              </HStack>
              <Divider mt={5} />
              <br />
              <Card>
                <CardHeader>
                  <HStack justifyContent={'space-between'}>
                    <Image src={logo} width={125} />

                    <Text fontWeight={600}>
                      Bujumbura, {Timer(data.order.createdAt)}
                    </Text>
                  </HStack>
                  <br />
                  {/* VENDOR INFOS AND CUSTOMER INFOS */}
                  {/* VENDOR INFOS */}
                  <Box>
                    <Heading size={'sm'}>Seller</Heading>
                    Name: PROBUCOM <br />
                    TIN: 40000920795
                    <br />
                    R\C N°: 10079/17
                    <br />
                    B.P: 955 Bujumbura
                    <br />
                    Phone Number: 22 28 14 63/64
                    <br />
                    Address: Bujumbura - Burundi, Mukaza Q. Rohero I <br />
                    Is VAT Payer: YES
                  </Box>
                  <br />
                  {/* CUSTOMER INFOS */}
                  <Box>
                    <Heading size={'sm'}>Customer</Heading>
                    Name: {data.order.createdBy.name}
                    <br />
                    Phone: {data.order.createdBy.phone}
                    <br />
                    {/* Adresse: {data.order.customer.commune} -{' '} */}
                    Address: {data.order.createdBy.city} <br />
                  </Box>
                </CardHeader>

                {/* INVOICE DETAILS */}
                <CardBody>
                  <Heading size={'md'} textAlign={'center'}>
                    Invoice No: {data.order.orderId}
                  </Heading>

                  {/* TABLE */}
                  <br />
                  <TableContainer>
                    <Table size={'sm'} marginTop={'2'} variant="striped">
                      <Thead>
                        <Tr>
                          <Th>Item</Th>
                          <Th isNumeric>Qty</Th>
                          <Th isNumeric>Unit Price</Th>
                          <Th isNumeric>Total</Th>
                        </Tr>
                      </Thead>

                      <Tbody>
                        {data.order?.items?.map((val, index) => (
                          <Tr key={index}>
                            <Td>
                              {val.name}{' '}
                              {val.option?.title ? (
                                <span style={{ fontStyle: 'italic' }}>
                                  ({val.option.title} - {val.option.name})
                                </span>
                              ) : (
                                ''
                              )}
                            </Td>
                            <Td isNumeric>{val.qty.toLocaleString()}</Td>
                            <Td isNumeric>{val.price.toLocaleString()}</Td>
                            <Td isNumeric>{val.amount?.toLocaleString()}</Td>
                          </Tr>
                        ))}

                        <Tr>
                          <Td fontWeight={500}>TOTAL HTVA</Td>
                          <Td></Td>
                          <Td></Td>

                          <Td isNumeric fontWeight={500}>
                            {data.order.amount.toLocaleString()}{' '}
                            {data.order.currency}
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>VAT</Td>
                          <Td></Td>
                          <Td></Td>

                          <Td isNumeric>
                            {data.order.vat.toLocaleString()}{' '}
                            {data.order.currency}
                          </Td>
                        </Tr>

                        <Tr>
                          <Td fontWeight={700}>TOTAL</Td>
                          <Td></Td>
                          <Td></Td>
                          <Td isNumeric fontWeight={700}>
                            {data.order.total.toLocaleString()}{' '}
                            {data.order.currency}
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>

                  <br />
                  <br />
                  <br />
                  {/* <Text>Pour la société PROBUCOM</Text> */}
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        ) : (
          <></>
        )}
      </Container>
    </Box>
  );
}
