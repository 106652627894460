import React, { useEffect } from 'react';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Heading,
  Text,
} from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

const Privacy = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <Box style={{ minHeight: '80vh' }}>
      <Container maxW={'container.xl'}>
        <Breadcrumb
          py={5}
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink onClick={() => navigate('/')}>Home</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Privacy Policy</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Heading mb={2}>Privacy Policy</Heading>

        <Text fontSize="lg" mb={4}>
          Welcome to Future City Technology! This Privacy Policy outlines how we
          collect, use, disclose, and safeguard your personal information when
          you use our e-commerce platform available at fctech.bi.
        </Text>

        <Text fontSize="lg" mb={4}>
          By using Future City Technology, you agree to the terms of this
          Privacy Policy. If you do not agree with the terms of this Privacy
          Policy, please do not access or use our services.
        </Text>

        <Heading mt={6} mb={4} size="md">
          1. Information We Collect
        </Heading>

        <Text fontSize="lg" mb={4}>
          1.1. <strong>Personal Information:</strong> We may collect personal
          information such as your name, email address, billing details,
          shipping address, and phone number when you register or make a
          purchase on our platform.
          <br />
          1.2. <strong>Usage Information:</strong> We collect information about
          your interactions with our website, including pages visited, time
          spent, and referring URLs. This may include device information, IP
          address, and browser type.
          <br />
          1.3. <strong>Payment Information:</strong> When making a purchase, we
          collect payment information, including credit card details. This
          information is securely processed by our payment service providers.
        </Text>

        <Heading mt={6} mb={4} size="md">
          2. How We Use Your Information
        </Heading>

        <Text fontSize="lg" mb={4}>
          2.1. We use your personal information to process orders, provide
          customer support, and improve our services. This includes order
          fulfillment, order confirmation, and communication regarding your
          purchases.
          <br />
          2.2. Your usage information helps us analyze trends, track user
          activities, and enhance the user experience on our platform. We may
          use this data for marketing purposes and to personalize content.
          <br />
          2.3. Payment information is used for transaction processing and fraud
          prevention. We do not store credit card information on our servers.
        </Text>

        <Heading mt={6} mb={4} size="md">
          3. Information Sharing and Disclosure
        </Heading>

        <Text fontSize="lg" mb={4}>
          3.1. We do not sell, trade, or otherwise transfer your personal
          information to third parties without your consent, except as described
          in this Privacy Policy.
          <br />
          3.2. We may share your information with trusted third-party service
          providers who assist us in operating our website, conducting business,
          or servicing you. These parties agree to keep your information
          confidential.
          <br />
          3.3. We may disclose your information if required by law or to protect
          our rights, privacy, safety, or property. This includes responding to
          legal requests or law enforcement inquiries.
        </Text>

        {/* ... Additional sections ... */}

        <Heading mt={6} mb={4} size="md">
          6. Changes to This Privacy Policy
        </Heading>

        <Text fontSize="lg" mb={4}>
          6.1. We may update this Privacy Policy from time to time. We will
          notify you of any changes by posting the new Privacy Policy on this
          page.
          <br />
          6.2. Changes are effective immediately upon posting. Please review
          this Privacy Policy periodically for any updates.
        </Text>

        <Text fontSize="lg" mb={4}>
          If you have any questions or concerns regarding this Privacy Policy,
          please contact us at{' '}
          <a href="mailto:support@fctech.bi">support@fctech.bi</a>.
        </Text>
      </Container>
    </Box>
  );
};

export default Privacy;
