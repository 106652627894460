import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
  Center,
} from '@chakra-ui/react';
import { isValidEmail } from '../config/validations';
import { useMutation } from '@apollo/client';
import { CREATE_ASSISTANCE } from '../config/graphql';
import Error from './Error';

function AssistanceForm({ close }) {
  const [assist, setAssist] = useState('');

  const [createAssistance, { loading, error, data }] =
    useMutation(CREATE_ASSISTANCE);

  const handleOnChange = event => {
    setAssist({ ...assist, [event.target.name]: event.target.value });
  };
  const handleOnSubmit = event => {
    if (assist.phone) {
      assist.phone = parseInt(assist.phone);
    }
    if (!isValidEmail(assist.email)) {
      alert('Invalid Email!');
    } else {
      createAssistance({ variables: { ...assist } })
        .then(data => {
          setTimeout(() => {
            alert('Message sent');
            close();
          }, 300);
        })
        .catch(e => console.log(e));
    }

    event.preventDefault();
  };
  return (
    <Box
      p={4}
      bg="white"
      borderRadius="md"
      boxShadow="lg"
      maxW="xs"
      mt="1"
      mb="1"
      mx="auto"
    >
      <FormControl id="name" isRequired>
        <FormLabel>Name</FormLabel>
        <Input name="name" onChange={handleOnChange} type="text" />
      </FormControl>

      <FormControl id="phone" mt={2}>
        <FormLabel>Phone Number</FormLabel>
        <Input name="phone" onChange={handleOnChange} type="text" />
      </FormControl>

      <FormControl id="email" isRequired>
        <FormLabel>Email address</FormLabel>
        <Input name="email" onChange={handleOnChange} type="email" />
      </FormControl>

      <FormControl id="description" isRequired mt={2}>
        <FormLabel>Message</FormLabel>
        <Textarea
          name="description"
          placeholder="i would like to know"
          _placeholder={{ color: 'gray.500' }}
          // type="text"
          onChange={handleOnChange}
        />
      </FormControl>

      <Center>
        <Button
          isLoading={loading}
          onClick={handleOnSubmit}
          loadingText="Sending"
          size="md"
          bg={'brand.primary'}
          color={'white'}
          w={'full'}
          _hover={{ bg: 'brand.hoverPrimary' }}
          mt={2}
        >
          Send
        </Button>
      </Center>

      {error ? <Error error={error} /> : <></>}
      {/* </> */}
      {/* )} */}
    </Box>
  );
}

export default AssistanceForm;
