import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Timer } from '../../config/functions';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import { GET_ORDERS } from '../../config/graphql';
import { useQuery } from '@apollo/client';
import DeleteWarning from '../../components/DeleteWarning';

export default function Orders() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const { data, error, loading } = useQuery(GET_ORDERS);

  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState(0);
  const [deleteId, setDeleteId] = useState('');
  const [orderId, setOrderId] = useState('');
  const [show, setShow] = useState(false);

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  const handleShow = msg => {
    setShow(!msg);
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  if (data) {
    const pageCount = Math.ceil(data.orders.length / pageSize);

    const start = currentPage * pageSize;
    const end = start + pageSize;

    return (
      <Box style={{ minHeight: '80vh' }}>
        <DeleteWarning
          setOpen={setShow}
          isOpen={show}
          data={{
            operation: 'order',
            id: deleteId,
            orderId: orderId,
          }}
        />
        <Container maxW={'container.xl'}>
          <Container py={5} maxWidth={'container.xl'}>
            <Breadcrumb
              spacing="8px"
              separator={<ChevronRightIcon color="gray.500" />}
            >
              <BreadcrumbItem>
                <BreadcrumbLink onClick={() => navigate('/')}>
                  Home
                </BreadcrumbLink>
              </BreadcrumbItem>

              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink>My Orders</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            <br />
            {loading ? <Loading /> : <></>}
            {error ? <Error error={error} /> : <></>}
            {data ? (
              <Box>
                <TableContainer>
                  <Table size={'sm'} marginTop={'2'} variant="striped">
                    <Thead>
                      <Tr>
                        <Th>Date</Th>
                        <Th>Order No</Th>
                        <Th>Status</Th>
                        <Th isNumeric>Amount</Th>
                        <Th>Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data.orders?.slice(start, end).map((val, index) => (
                        <Tr key={index}>
                          <Td>{Timer(val.createdAt)}</Td>
                          <Td>{val.orderId}</Td>

                          {val.status === 'completed' ? (
                            <Td>
                              <Badge variant="solid" colorScheme="green">
                                Completed
                              </Badge>
                            </Td>
                          ) : (
                            <></>
                          )}

                          {val.status === 'paid' ? (
                            <Td>
                              <Badge variant="solid" colorScheme="blue">
                                Paid
                              </Badge>
                            </Td>
                          ) : (
                            <></>
                          )}

                          {val.status === 'pending payment' ? (
                            <Td>
                              <Badge colorScheme="red">Pending Payment</Badge>
                            </Td>
                          ) : (
                            <></>
                          )}

                          {val.status === 'processing' ? (
                            <Td>
                              <Badge colorScheme="green">Processing</Badge>
                            </Td>
                          ) : (
                            <></>
                          )}
                          <Td isNumeric>
                            {val.total.toLocaleString()} {val.currency}
                          </Td>
                          <Td>
                            <Button
                              onClick={() => navigate(`/orders/${val.id}`)}
                              mr={5}
                              bg={'brand.primary'}
                              color={'white'}
                              _hover={{ bg: 'brand.hoverPrimary' }}
                            >
                              Details
                            </Button>

                            <Button
                              onClick={() => {
                                setDeleteId(val.id);
                                setOrderId(val.orderId);
                                handleShow();
                              }}
                              colorScheme="red"
                            >
                              Delete
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
                <Box mt={4} display="flex" justifyContent="center">
                  <Button
                    onClick={() => handlePageChange(currentPage - 1)}
                    isDisabled={currentPage === 0}
                    mr={2}
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={() => handlePageChange(currentPage + 1)}
                    isDisabled={currentPage === pageCount - 1}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </Container>
        </Container>
      </Box>
    );
  }
}
