import {
  Button,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Image,
} from '@chakra-ui/react';
import auth from '../assets/Auth.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LOGIN } from '../config/graphql';
import { useMutation } from '@apollo/client';
import { isValidEmail } from '../config/validations';
import Error from '../components/Error';

export default function Auth() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [user, setUser] = useState();

  const [login, { error, loading }] = useMutation(LOGIN);

  const handleOnChange = event => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handleOnSubmit = event => {
    if (!isValidEmail(user.email)) {
      alert('Invalid email!');
    } else {
      login({ variables: { ...user } })
        .then(data => {
          const authData = data.data.login;
          localStorage.setItem('user', JSON.stringify(authData));
          if (location.state?.from) {
            navigate(location.state.from);
          } else {
            navigate('/');
          }
        })
        .catch(e => console.log(e));
    }
    event.preventDefault();
  };

  return (
    <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
        <Stack spacing={4} w={'full'} maxW={'md'}>
          <Heading fontSize={'2xl'}>Sign in to your account</Heading>
          <form>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input onChange={handleOnChange} name="email" type="email" />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                onChange={handleOnChange}
                name="password"
                type="password"
              />
            </FormControl>
            <Stack spacing={6}>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}
              >
                <Text
                  cursor={'pointer'}
                  color={'brand.primary'}
                  onClick={() => navigate('/forgot-password')}
                >
                  Forgot password?
                </Text>
              </Stack>
              <Button
                type="submit"
                isDisabled={loading}
                bg={'brand.primary'}
                onClick={handleOnSubmit}
                color={'white'}
                w={'full'}
                _hover={{ bg: 'brand.hoverPrimary' }}
                variant={'solid'}
              >
                Sign in
              </Button>

              {error ? <Error error={error} /> : <></>}
              <Text
                cursor={'pointer'}
                color={'brand.primary'}
                onClick={() => navigate('/signup')}
              >
                Don't have an account? Create one here!
              </Text>
            </Stack>
          </form>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image alt={'Login Image'} objectFit={'cover'} src={auth} />
      </Flex>
    </Stack>
  );
}
