import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//CONNECTING TO BACKEND IMPORT

import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import handleRefresh, { isValidToken } from './api/refreshToken';

// END HERE

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

// CODES FOR CONNECTING
const authLink = new ApolloLink(async (operation, forward) => {
  // GETTING TOKEN FROM LOCALSTORAGE
  let authData = JSON.parse(localStorage.getItem('user'));
  let accessToken = authData?.accessToken;
  let refreshToken = authData?.refreshToken;

  if (accessToken) {
    const result = isValidToken(accessToken);
    if (!result) {
      try {
        const response = await handleRefresh(accessToken, refreshToken);
        // Use the setContext method to set the HTTP headers.
        if (response.data.refreshTokens) {
          localStorage.setItem(
            'user',
            JSON.stringify(response.data.refreshTokens)
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  authData = JSON.parse(localStorage.getItem('user'));
  accessToken = authData?.accessToken;
  refreshToken = authData?.refreshToken;
  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: authData ? `Bearer ${authData.accessToken}` : '',

      'apollo-require-preflight': true,
      // "x-apollo-operation-name": "uploading",
      // "Content-type": "multipart/mixed",
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const api_url = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const uploadLink = createUploadLink({
  uri: `${api_url}/api`,
});

export const client = new ApolloClient({
  link: authLink.concat(uploadLink), // Chain it with the HttpLink ,
  cache: new InMemoryCache(),
});

root.render(
  <StrictMode>
    <ApolloProvider client={client}>
      <ColorModeScript />
      <App />
    </ApolloProvider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
