import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  CardBody,
  Container,
  Flex,
  FormControl,
  Heading,
  Image,
  Select,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT } from '../../config/graphql';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import Gallery from '../../components/Product/Gallery';
import defaultImage from '../../assets/default.png';

export default function ProductDetails({ addToCart, currency }) {
  const navigate = useNavigate();
  const { id } = useParams();

  const imageUrl =
    process.env.REACT_APP_IMAGE_URL || 'http://192.168.0.23:2222';

  const [variation, setVariation] = useState(-1);

  const handleVariation = e => {
    setVariation(e.target.value);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const { loading, error, data } = useQuery(GET_PRODUCT, {
    variables: { id },
  });

  return (
    <Box>
      <Container py={5} maxWidth={'container.xl'}>
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink onClick={() => navigate('/')}>Home</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Product Details</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Container>
      <Box bg={'gray.50'}>
        {loading ? <Loading /> : <></>}
        {error ? <Error error={error} /> : <></>}
        {data ? (
          <Container maxW={'container.xl'}>
            <SimpleGrid
              alignItems={'center'}
              columns={{ base: 1, lg: 2 }}
              spacing={{ base: 4, md: 5 }}
            >
              <Flex>
                {data?.product.imageUrl?.length >= 1 ? (
                  <>
                    {data?.product?.imageUrl?.length < 2 ? (
                      <>
                        <Image
                          rounded={'md'}
                          alt={'product image'}
                          src={imageUrl + data.product.imageUrl[0]}
                          fit={'contain'}
                          align={'center'}
                          w={'100%'}
                          h={{ base: '100%', sm: '400px', lg: '500px' }}
                        />
                      </>
                    ) : (
                      <Card>
                        <CardBody>
                          <Gallery data={data.product.imageUrl} />
                        </CardBody>
                      </Card>
                    )}
                  </>
                ) : (
                  <>
                    <Image
                      rounded={'md'}
                      alt={'product image'}
                      src={defaultImage}
                      fit={'contain'}
                      align={'center'}
                      w={'100%'}
                      h={{ base: '100%', sm: '400px', lg: '500px' }}
                    />
                  </>
                )}
              </Flex>
              <Stack spacing={{ base: 3, md: 5 }}>
                <Box as={'header'}>
                  <Heading
                    lineHeight={1.1}
                    fontWeight={600}
                    fontSize={{ base: 'xl', sm: '2xl', lg: '3xl' }}
                  >
                    {data.product.name}
                  </Heading>
                </Box>
                <div>
                  <Text fontSize={'lg'}>{data.product.description}</Text>
                </div>
                {/* OPTIONS */}
                {data.product?.options?.variations?.length > 0 ? (
                  <>
                    <Text>
                      <Badge colorScheme="gray" variant={'solid'}>
                        {data.product.options.title}
                      </Badge>
                    </Text>
                    <FormControl>
                      {/* <FormLabel>Choose:</FormLabel> */}
                      <Select name="price" onChange={handleVariation}>
                        <option value={-1}>--</option>
                        {data.product?.options?.variations?.map(
                          (val, index) => (
                            <option key={index} value={index}>
                              {val.name}
                            </option>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </>
                ) : (
                  <></>
                )}
                {/* PRICE */}
                {data.product?.options?.variations?.length > 0 ? (
                  <Text color={'gray.500'} fontSize={'xl'} fontWeight={'300'}>
                    {currency === 'BIF' ? (
                      <>
                        {data.product.options.variations[
                          variation
                        ]?.priceInBIF?.toLocaleString('en-FR', {})}{' '}
                        {currency}
                      </>
                    ) : (
                      <></>
                    )}
                    {currency === 'USD' ? (
                      <>
                        {data.product.options.variations[
                          variation
                        ]?.priceInUSD?.toLocaleString('en-FR', {})}{' '}
                        {currency}
                      </>
                    ) : (
                      <></>
                    )}
                    {currency === 'EUR' ? (
                      <>
                        {data.product.options.variations[
                          variation
                        ]?.priceInEUR?.toLocaleString('en-FR', {})}{' '}
                        {currency}
                      </>
                    ) : (
                      <></>
                    )}
                  </Text>
                ) : (
                  <Text color={'gray.500'} fontSize={'xl'} fontWeight={'300'}>
                    {data.product.discount ? (
                      <>
                        {currency === 'BIF' ? (
                          (
                            data?.product?.priceInBIF *
                            (1 - data?.product?.discount)
                          )?.toLocaleString('en-FR', {})
                        ) : (
                          <></>
                        )}
                        {currency === 'USD' ? (
                          (
                            data?.product?.priceInUSD *
                            (1 - data?.product?.discount)
                          )?.toLocaleString('en-FR', {})
                        ) : (
                          <></>
                        )}
                        {currency === 'EUR' ? (
                          (
                            data?.product?.priceInEUR *
                            (1 - data?.product?.discount)
                          )?.toLocaleString('en-FR', {})
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        {currency === 'BIF' ? (
                          data?.product?.priceInBIF?.toLocaleString('en-FR', {})
                        ) : (
                          <></>
                        )}
                        {currency === 'USD' ? (
                          data?.product?.priceInUSD?.toLocaleString('en-FR', {})
                        ) : (
                          <></>
                        )}
                        {currency === 'EUR' ? (
                          data?.product?.priceInEUR?.toLocaleString('en-FR', {})
                        ) : (
                          <></>
                        )}
                      </>
                    )}{' '}
                    {currency}
                  </Text>
                )}
                {/* // ADD TO CART */}
                <Button
                  w={'full'}
                  onClick={() => {
                    addToCart({
                      id:
                        data.product?.options?.variations?.length > 0
                          ? data.product.id +
                            '-/-' +
                            data.product.options.variations[variation].name
                          : data.product.id,
                      name: data.product.name,
                      item_code: data.product.productCode,
                      imageUrl: data.product.imageUrl[0],
                      qty: data.product.qty,
                      currency: currency,
                      option:
                        data.product?.options?.variations?.length > 0
                          ? {
                              title: data.product.options.title,
                              name: data.product.options.variations[variation]
                                .name,
                            }
                          : null,
                      price:
                        data.product?.options?.variations?.length > 0
                          ? currency === 'BIF'
                            ? data.product.options.variations[variation]
                                .priceInBIF
                            : currency === 'USD'
                            ? data.product.options.variations[variation]
                                .priceInUSD
                            : currency === 'EUR'
                            ? data.product.options.variations[variation]
                                .priceInEUR
                            : 0
                          : currency === 'BIF'
                          ? data?.product?.discount
                            ? data?.product?.priceInBIF *
                              (1 - data?.product?.discount)
                            : data?.product?.priceInBIF
                          : currency === 'USD'
                          ? data?.product?.discount
                            ? data?.product?.priceInUSD *
                              (1 - data?.product?.discount)
                            : data?.product?.priceInUSD
                          : data?.product?.discount
                          ? data?.product?.priceInEUR *
                            (1 - data?.product?.discount)
                          : data?.product?.priceInEUR,
                    });
                    navigate('/cart');
                  }}
                  mt={3}
                  isDisabled={
                    variation == -1 &&
                    data.product?.options?.variations?.length > 0
                  }
                  size={'lg'}
                  bg={'brand.primary'}
                  color={'white'}
                  textTransform={'uppercase'}
                  _hover={{
                    transform: 'translateY(2px)',
                    boxShadow: 'lg',
                  }}
                >
                  ADD TO CART
                </Button>
              </Stack>
            </SimpleGrid>
          </Container>
        ) : (
          <></>
        )}
      </Box>
      <br />
      <br />
    </Box>
  );
}
