import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { DELETE_ORDER, GET_ORDERS } from '../config/graphql';
import Error from './Error';

function DeleteWarning({ isOpen, setOpen, data }) {
  function handleClose() {
    setOpen(false);
  }

  const [deleteOrder, { loading: loadingOrder, error: errorOrder }] =
    useMutation(DELETE_ORDER, {
      refetchQueries: [{ query: GET_ORDERS }],
    });

  function handleDeleteOrder(id) {
    deleteOrder({ variables: { ...{ id } } })
      .then(data => {
        handleClose();
      })
      .catch(e => console.log(e));
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={() => setOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cancel Order</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Delete Order No {data.orderId}</Text>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} variant="ghost" onClick={() => setOpen(false)}>
              Close
            </Button>
            <Button
              isLoading={loadingOrder}
              color={'white'}
              bg="brand.primary"
              _hover={{ bg: 'brand.hoverPrimary' }}
              mr={3}
              onClick={() => {
                handleDeleteOrder(data.id);
              }}
              loadingText="Deleting"
            >
              Delete
            </Button>
          </ModalFooter>

          {errorOrder ? <Error error={errorOrder} /> : <></>}
        </ModalContent>
      </Modal>
    </>
  );
}

export default DeleteWarning;
