import React, { useEffect, useState } from 'react';
import FilterHeader from '../components/FilterHeader';
import SlidingHeader from '../components/SlidingHeader';
import Products from '../components/Product/Products';
import { useQuery } from '@apollo/client';
import { GET_PRODUCTS } from '../config/graphql';
import Loading from '../components/Loading';
import Error from '../components/Error';
import { FloatingWhatsApp } from 'react-floating-whatsapp2';
import logo from '../assets/logo.png';

const phone = process.env.REACT_APP_PHONE_NUMBER || '25766536050';

export default function Homepage({
  cartItems,
  currency,
  clearCart,
  addToCart,
  removeItem,
  searchTerm,
  setSearchTerm,
}) {
  const { data, error, loading } = useQuery(GET_PRODUCTS);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [category, setCategory] = useState('');

  return (
    <div style={{ minHeight: '80vh' }}>
      <FilterHeader
        cartItems={cartItems}
        clearCart={clearCart}
        setSearchTerm={setSearchTerm}
        removeItem={removeItem}
        setCategory={setCategory}
        category={category}
      />
      <br />
      <br />
      <br />
      <SlidingHeader />
      {loading ? <Loading /> : <></>}
      {error ? <Error error={error} /> : <></>}
      {data ? (
        <Products
          currency={currency}
          products={data.products}
          addToCart={addToCart}
          searchTerm={searchTerm}
          category={category}
          title={'Recent Products'}
        />
      ) : (
        <></>
      )}
      <FloatingWhatsApp
        statusMessage={'online'}
        phoneNumber={phone}
        accountName="Future City Technology"
        avatar={logo}
      />
    </div>
  );
}
