import React, { useEffect, useState } from 'react';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
} from '@chakra-ui/react';
import Products from '../components/Product/Products';
import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useQuery } from '@apollo/client';
import { GET_PRODUCTS } from '../config/graphql';
import Loading from '../components/Loading';
import Error from '../components/Error';

// Sample product data

const itemsPerPage = 9; // Number of products per page

const Shop = ({ addToCart }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, error, loading } = useQuery(GET_PRODUCTS);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the products for the current page
  let currentProducts = [];
  if (data) {
    currentProducts = data.products.slice(startIndex, endIndex);
  }

  // Handle pagination button click
  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  const navigate = useNavigate();

  return (
    <Box>
      <Container py={5} maxWidth={'container.xl'}>
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
        >
          <BreadcrumbItem>
            <BreadcrumbLink onClick={() => navigate('/')}>Home</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Shop Page</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Container>
      {loading ? <Loading /> : <></>}
      {error ? <Error error={error} /> : <></>}
      {}
      <Products
        addToCart={addToCart}
        searchTerm={''}
        category={''}
        products={currentProducts}
        title={'All Products'}
      />
      <Box mt={4} textAlign="center">
        <Button
          onClick={() => handlePageChange(currentPage - 1)}
          isDisabled={currentPage === 1}
        >
          Previous Page
        </Button>
        <Button
          onClick={() => handlePageChange(currentPage + 1)}
          ml={2}
          isDisabled={endIndex >= data?.products.length}
        >
          Next Page
        </Button>
      </Box>
      <br />
    </Box>
  );
};

export default Shop;
