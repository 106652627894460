'use client';

import { ReactNode } from 'react';

import {
  Box,
  Container,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import logo from '../assets/logo.png';
import { useNavigate } from 'react-router-dom';

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export default function Footer() {
  const navigate = useNavigate();
  return (
    <Box
      bg={useColorModeValue('gray.200', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
    >
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 1fr 1fr' }}
          spacing={8}
        >
          <Stack spacing={6}>
            <Box>
              <Image w={135} src={logo} alt="Logo" title="Logo" />
            </Box>
            <Text fontSize={'sm'}>
              © {new Date().getFullYear()} PROBUCOM. All rights reserved
            </Text>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Quick Links</ListHeader>
            <Box as="a" cursor={'pointer'} onClick={() => navigate('/signup')}>
              Create an account
            </Box>
            <Box as="a" cursor={'pointer'} onClick={() => navigate('/shop')}>
              Shop
            </Box>
            <Box as="a" cursor={'pointer'} onClick={() => navigate('/cart')}>
              Cart
            </Box>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Company</ListHeader>
            <Box
              as="a"
              cursor={'pointer'}
              onClick={() => navigate('/terms-conditions')}
            >
              Terms and Conditions
            </Box>
            <Box
              as="a"
              cursor={'pointer'}
              onClick={() => navigate('/privacy-policy')}
            >
              Privacy Policy
            </Box>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Support</ListHeader>
            <Box as="a" cursor={'pointer'} onClick={() => navigate('/contact')}>
              Contact us
            </Box>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Follow Us</ListHeader>
            <Box as="a" href={'#'}>
              Facebook
            </Box>
            <Box as="a" href={'#'}>
              Instagram
            </Box>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
