import { AbsoluteCenter, Box } from '@chakra-ui/react';
import React from 'react';

export default function TitleDivider({ text }) {
  return (
    <Box position="relative" padding="10">
      <AbsoluteCenter fontSize={23} fontWeight={600}>
        {text}
      </AbsoluteCenter>
    </Box>
  );
}
