import {
  Box,
  Flex,
  Avatar,
  HStack,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Image,
  Container,
  MenuItemOption,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import logo from '../assets/logo.png';
import { useNavigate, Link as LinkMenu } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

interface Props {
  children: React.ReactNode;
}

const Links = [
  { title: 'Home', link: '/' },
  { title: 'Shop', link: 'shop' },
  { title: 'Cart', link: 'cart' },
  { title: 'Contact us', link: 'contact' },
];

const NavLink = (props: Props) => {
  const navigate = useNavigate();

  const { children } = props;
  return (
    <Box
      as="a"
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
      cursor={'pointer'}
      onClick={() => navigate(children.link)}
    >
      {children.title}
    </Box>
  );
};

export default function Navbar({ currency, handleCurrency }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  //-----------------------------------------
  const client = useApolloClient();
  let user = JSON.parse(localStorage.getItem('user'));
  const imageUrl =
    process.env.REACT_APP_IMAGE_URL || 'http://192.168.0.23:2222';

  const logoutHandler = async () => {
    // const user = JSON.parse(localStorage.getItem('user'));
    // const refreshToken = user.refreshToken;

    // window.localStorage.clear();
    localStorage.removeItem('user');
    client.clearStore();
    navigate('/');

    // handleLogout(refreshToken);
  };

  return (
    <>
      <Box
        bg={useColorModeValue('gray.100', 'gray.900')}
        borderBottom="1px solid transparent" // Transparent border to reserve space for the blurred border
        boxShadow="0px 2px 5px rgba(0, 0, 0, 0.1)"
        position="fixed"
        zIndex={3}
        w="100%"
      >
        <Container maxW={'container.xl'}>
          <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
            <IconButton
              size={'md'}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={'Open Menu'}
              display={{ md: 'none' }}
              onClick={isOpen ? onClose : onOpen}
            />
            <HStack spacing={8} alignItems={'center'}>
              <Box cursor={'pointer'} onClick={() => navigate('/')}>
                <Image width={125} src={logo} />
              </Box>
              <HStack
                as={'nav'}
                spacing={4}
                display={{ base: 'none', md: 'flex' }}
              >
                {Links.map((link, index) => (
                  <NavLink key={index}>{link}</NavLink>
                ))}
                <Menu>
                  <MenuButton as={Button} cursor={'pointer'} minW={0}>
                    Currency in ({currency})
                  </MenuButton>
                  <MenuList>
                    <MenuItemOption
                      onClick={() => handleCurrency('BIF')}
                      value="BIF"
                    >
                      {currency === 'BIF' ? <b>✔️BIF</b> : 'BIF'}
                    </MenuItemOption>
                    <MenuItemOption
                      onClick={() => handleCurrency('USD')}
                      value="USD"
                    >
                      {currency === 'USD' ? <b>✔️USD</b> : 'USD'}
                    </MenuItemOption>
                    <MenuItemOption
                      onClick={() => handleCurrency('EUR')}
                      value="EUR"
                    >
                      {currency === 'EUR' ? <b>✔️EUR</b> : 'EUR'}
                    </MenuItemOption>
                  </MenuList>
                </Menu>
              </HStack>
            </HStack>
            <Flex alignItems={'center'}>
              {user ? (
                <>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rounded={'full'}
                      variant={'link'}
                      cursor={'pointer'}
                      minW={0}
                    >
                      <Avatar
                        bg="orange.300"
                        size={'sm'}
                        name={user?.name || 'User'}
                        src={imageUrl + user?.imageUrl}
                      />
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => navigate('/orders')}>
                        My Orders
                      </MenuItem>
                      <MenuItem onClick={() => navigate('/account')}>
                        My Account
                      </MenuItem>
                      <MenuItem onClick={() => navigate('/change-password')}>
                        Change password
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem onClick={logoutHandler}>Log out</MenuItem>
                    </MenuList>
                  </Menu>
                </>
              ) : (
                <>
                  <Button
                    // as={'a'}
                    fontSize={'sm'}
                    fontWeight={400}
                    variant={'link'}
                    onClick={() => navigate('/signup')}
                  >
                    Sign up
                  </Button>
                  <Button
                    ml={5}
                    display={{ base: 'none', md: 'inline-flex' }}
                    fontSize={'sm'}
                    fontWeight={400}
                    onClick={() => navigate('/signin')}
                    color={'white'}
                    bg={'brand.primary'}
                    _hover={{ bg: 'brand.hoverPrimary' }}
                  >
                    Sign in
                  </Button>
                </>
              )}
            </Flex>
          </Flex>

          {isOpen ? (
            <Box pb={4} display={{ md: 'none' }}>
              <Stack as={'nav'} spacing={4}>
                {Links.map(link => (
                  <LinkMenu key={link.link} to={link.link} onClick={onClose}>
                    {link.title}
                  </LinkMenu>
                ))}
                <Menu>
                  <MenuButton as={Button} cursor={'pointer'} minW={0}>
                    Currency in ({currency})
                  </MenuButton>
                  <MenuList>
                    <MenuItemOption
                      onClick={() => {
                        handleCurrency('BIF');
                        onClose();
                      }}
                      value="BIF"
                    >
                      {currency === 'BIF' ? <b>✔️BIF</b> : 'BIF'}
                    </MenuItemOption>
                    <MenuItemOption
                      onClick={() => {
                        handleCurrency('USD');
                        onClose();
                      }}
                      value="USD"
                    >
                      {currency === 'USD' ? <b>✔️USD</b> : 'USD'}
                    </MenuItemOption>
                    <MenuItemOption
                      onClick={() => {
                        handleCurrency('EUR');
                        onClose();
                      }}
                      value="EUR"
                    >
                      {currency === 'EUR' ? <b>✔️EUR</b> : 'EUR'}
                    </MenuItemOption>
                  </MenuList>
                </Menu>
              </Stack>
            </Box>
          ) : null}
        </Container>
      </Box>
    </>
  );
}
