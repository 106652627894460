import {
  Button,
  Container,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  HStack,
  Box,
  Circle,
  Divider,
  Text,
  VStack,
  Image,
  IconButton,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBagShopping,
  faComputer,
  faHouseFire,
  faMicrochip,
  faMobileScreen,
  faNetworkWired,
  faPrint,
  faSearch,
  faTrashCan,
  faVideoCamera,
} from '@fortawesome/free-solid-svg-icons';
import { HamburgerIcon } from '@chakra-ui/icons';
import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { trimTitle } from '../config/functions';

export default function FilterHeader({
  cartItems,
  clearCart,
  setSearchTerm,
  setCategory,
  category,
  removeItem,
}) {
  const navigate = useNavigate();
  const imageUrl =
    process.env.REACT_APP_IMAGE_URL || 'http://192.168.0.23:2222';

  return (
    <Container
      maxW={'container.2xl'}
      borderBottom="1px solid transparent" // Transparent border to reserve space for the blurred border
      boxShadow="0px 2px 5px rgba(0, 0, 0, 0.1)"
      position="fixed"
      zIndex={2}
      w="100%"
      bg={'white'}
      mt={-2}
      pt={5}
      pb={5}
    >
      <HStack display={'flex'} justify={'space-between'} alignItems={'center'}>
        {/* MENU CATEGORIES - Hidden on Mobile */}
        <Box display={{ base: 'none', md: 'none', lg: 'block' }}>
          <Menu>
            <MenuButton as={Button} bg={'white'}>
              <HamburgerIcon /> Categories
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => setCategory('')}>All</MenuItem>
              <MenuItem
                onClick={() => setCategory('Computers')}
                icon={<FontAwesomeIcon icon={faComputer} />}
              >
                Computers
              </MenuItem>
              <MenuItem
                onClick={() => setCategory('Fiber Optic')}
                icon={<FontAwesomeIcon icon={faNetworkWired} />}
              >
                Fiber Optic
              </MenuItem>

              <MenuItem
                onClick={() => setCategory('CCTV Surveillance Camera')}
                icon={<FontAwesomeIcon icon={faVideoCamera} />}
              >
                CCTV Camera
              </MenuItem>

              <MenuItem
                onClick={() => setCategory('Fire Detection Systems')}
                icon={<FontAwesomeIcon icon={faHouseFire} />}
              >
                Fire Detection Systems
              </MenuItem>
              <MenuItem
                onClick={() => setCategory('Printers')}
                icon={<FontAwesomeIcon icon={faPrint} />}
              >
                Printers
              </MenuItem>
              <MenuItem
                onClick={() => setCategory('Electronics')}
                icon={<FontAwesomeIcon icon={faMobileScreen} />}
              >
                Electronics
              </MenuItem>
              <MenuItem
                onClick={() => setCategory('Accessories')}
                icon={<FontAwesomeIcon icon={faMicrochip} />}
              >
                Accessories
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>

        {/* MENU SEARCH */}
        <InputGroup>
          <InputLeftAddon>{category === '' ? 'All' : category}</InputLeftAddon>
          <Input
            placeholder="Hp Elitebook"
            onChange={e => setSearchTerm(e.target.value)}
          />
          <InputRightAddon>
            <FontAwesomeIcon icon={faSearch} />
          </InputRightAddon>
        </InputGroup>

        {/* CART */}
        <Menu>
          <MenuButton
            as={Box}
            rounded={'full'}
            // variant={'link'}
            cursor={'pointer'}
            minW={10}
          >
            <HStack>
              <FontAwesomeIcon size="xl" icon={faBagShopping} />
              <Circle
                as={'span'}
                color={'white'}
                position={'absolute'}
                top={'45px'}
                right={'25px'}
                fontSize={'0.8rem'}
                bgColor={'brand.primary'}
                zIndex={99}
                p={'2px'}
                h={'5'}
                w={'5'}
              >
                {cartItems.length}
              </Circle>
            </HStack>
          </MenuButton>
          <MenuList maxH={400} overflowY={'scroll'}>
            {cartItems?.map((val, index) => (
              <Box key={index} p={4}>
                <HStack justify={'space-between'}>
                  <Image w={12} src={imageUrl + val.imageUrl} alt={val.name} />
                  <VStack>
                    <Text>{trimTitle(val.name)}</Text>
                    {val.option ? (
                      <Text>
                        {val.option.title}: {val.option.name}
                      </Text>
                    ) : (
                      <></>
                    )}
                    <Text>
                      {val.qty} x {val.price?.toLocaleString('en-FR', {})}{' '}
                      {val.currency}
                    </Text>
                  </VStack>

                  <IconButton
                    color={'gray.800'}
                    onClick={() => removeItem(val)}
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </IconButton>
                </HStack>

                <Divider />
              </Box>
            ))}
            {/* <MenuDivider /> */}
            {cartItems?.length > 0 ? (
              <HStack px={4} as={Box} justifyContent={'space-between'}>
                <Button onClick={clearCart}>Clear</Button>{' '}
                <Button
                  // size={'sm'}
                  onClick={() => navigate('/cart')}
                  bg={'brand.primary'}
                  _hover={{ bg: 'brand.hoverPrimary' }}
                  color={'white'}
                >
                  View
                </Button>
              </HStack>
            ) : (
              <Fragment></Fragment>
            )}
          </MenuList>
        </Menu>
      </HStack>
    </Container>
  );
}
