import React from 'react';
import ProductCard from './ProductCard';
import { Box, Divider, SimpleGrid } from '@chakra-ui/react';
import TitleDivider from '../TitleDivider';

export default function Products({
  products,
  currency,
  searchTerm,
  category,
  addToCart,
  title,
}) {
  return (
    <Box bg={'gray.100'}>
      <TitleDivider text={title} />
      <Divider />

      <SimpleGrid columns={[2, null, 3]} spacingX="10px" spacingY="20px">
        {products
          .filter(product => {
            if (
              product.category.toLowerCase().includes(category?.toLowerCase())
            ) {
              return product;
            }
          })
          .filter(product => {
            if (
              product.name.toLowerCase().includes(searchTerm?.toLowerCase())
            ) {
              return product;
            }
          })
          .slice(0, 9)
          .map((val, index) => (
            <Box key={index}>
              <ProductCard
                currency={currency}
                addToCart={addToCart}
                data={val}
              />
            </Box>
          ))}
      </SimpleGrid>
      <br />
    </Box>
  );
}
